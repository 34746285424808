import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import HomepageSearchBox from '@/components/packages/homepage-search-box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ClassNames from 'classnames';

const PREFIX = 'index';

const classes = {
  root: `${PREFIX}-root`,
  show: `${PREFIX}-show`,
  hide: `${PREFIX}-hide`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    boxShadow: 'none',
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Open Sans, sans-serif',
      display: 'flex!important',
      color: '#171717',
      fontSize: '13px',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        fontSize: '11px',
        paddingLeft: '10px',
      },
    },
  },
  [`& .${classes.show}`]: {
    display: 'block',
    position: 'relative',
  },
  [`& .${classes.hide}`]: {
    display: 'none',
  },
}));

export default function SearchInput(props) {
  const {
    width,
    height,
    style,
    placeholder,
    homepageSearchBox,
    handleChange,
    onSelectSearch,
    selectedCities,
    handleOpen,
    handleClickAway,
    is_open,
    // is_mobile, 
    propertyDetailsClick,
    keyword,
    module_type,
    name,
    cityInfo,
    isdisabled = false,
  } = props;

  const [searchInputKey, setSearchInputKey] = useState('');
  const [isMobile, setIsMobile] = useState(false); // Initially false for SSR

  useEffect(() => {
    if (typeof window !== 'undefined') { // Ensures it runs only on client side
      const handleResize = () => setIsMobile(window.innerWidth <= 768);

      handleResize(); // Set initial state
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);
  const handleSearchInputChange = (e) => {
    setSearchInputKey(e.target.value);
    if (typeof handleChange === "function") {
      handleChange(e);
    }
  };
  useEffect(() => {
    setSearchInputKey('');
  }, [cityInfo]);

  return (
    <Root className={classes.root} square={true}>
      <>
        <InputBase
          placeholder={placeholder}
          width={width}
          height={height}
          style={style}
          onClick={handleOpen}
          onChange={handleSearchInputChange}
          name={name}
          value={searchInputKey}
          autoComplete="off"
          readOnly={isdisabled}
        />
        {!isMobile && (
          <ClickAwayListener
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
            onClickAway={handleClickAway ? handleClickAway : () => void 0}
          >
            <div className={ClassNames([is_open ? classes.show : classes.hide])}>
              <HomepageSearchBox
                selectedCities={selectedCities}
                popularPartData={homepageSearchBox?.popularPart}
                localitiesData={homepageSearchBox?.localities}
                sublocalitiesData={homepageSearchBox?.sub_localities}
                propertiesData={homepageSearchBox?.properties}
                developersData={homepageSearchBox?.developers}
                close_search={handleClickAway}
                onSelectSearch={onSelectSearch}
                mobile={isMobile}
                propertyDetailsClick={propertyDetailsClick}
                module_type={module_type}
                keyword={keyword}
              />
            </div>
          </ClickAwayListener>
        )}
      </>
    </Root>
  );
}
