import Popover from '@mui/material/Popover';
import CommonHeaderText from '../../packages/common-header-text';
import { logoutUserApi } from '../../../redux/actions/main';
import { connect } from 'react-redux';
import { MYACCOUNT_HOMEBAZAAR } from '../../../config';
import Grid from '@mui/material/Grid2';
import useWindow from '@/hooks/useWindow';
import styles from './LoginPopOver.module.css'; // Import the CSS module

const login_menu = [
  {
    title: 'Dashboard',
    image: '/static/images/login_popover/dashboard.png',
  },
  {
    title: 'Shortlisted Properties',
    image: '/static/images/login_popover/property.png',
  },
  {
    title: 'Scheduled Tours',
    image: '/static/images/login_popover/building_1.png',
  },
  {
    title: 'My Profile',
    image: '/static/images/login_popover/user.png',
  },
  {
    title: 'List Your Property',
    image: '/static/images/login_popover/building.png',
  },
  {
    title: 'My Properties',
    image: '/static/images/login_popover/assets.png',
  },
  {
    title: 'My Leads',
    image: '/static/images/login_popover/agreement.png',
  },
  {
    title: 'Logout',
    image: '/static/images/login_popover/logout.png',
  },
];

const LoginPopover = (props = {}) => {
  const {
    openDrawer,
    anchorEl,
    closeLoginDrawer,
    access_token,
    logoutUser,
  } = props;
  const hasWindow = useWindow();

  const logout = () => {
    props.logoutUserApi({
      headers: {
        Authorization: 'Bearer ' + access_token,
      },
    });
    logoutUser();
    localStorage.setItem('resendCount', 0);
    if (hasWindow) {
      window.location.href = MYACCOUNT_HOMEBAZAAR.replace(/[']/g, '') + 'logout';
      window.location.reload();
    }
  };

  return (<>
    <Popover
      className={styles.root} // Use CSS module class
      open={openDrawer}
      disableScrollLock={true}
      anchorEl={anchorEl}
      onClose={closeLoginDrawer}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      marginThreshold={65}
      PaperProps={{
        style: {
          boxShadow: '0px 4px 15px -3px rgba(0,0,0,0.1)',
          borderTopLeftRadius: '0',
          borderTopRightRadius: '0',
          borderBottomLeftRadius: '15px',
          borderBottomRightRadius: '15px',
        },
        onMouseLeave: closeLoginDrawer,
      }}>
      {access_token && access_token !== '' && (
        <div className={styles.loginDrawer}> {/* Use CSS module class */}
          <Grid container>
            {login_menu &&
              login_menu.map((data, index) => {
                const { title, image } = data;
                return (
                  (<Grid
                        key={index}
                        // Use CSS module class
                        className={styles.row}
                        onClick={() => {
                          switch (index) {
                            case 0:
                              window.open(
                                MYACCOUNT_HOMEBAZAAR.replace(/[']/g, '') + 'home',
                                '_blank'
                              );
                              break;
                            case 1:
                              window.open(
                                MYACCOUNT_HOMEBAZAAR.replace(/[']/g, '') + 'wishlists/list'
                              );
                              break;
                            case 2:
                              window.open(
                                MYACCOUNT_HOMEBAZAAR.replace(/[']/g, '') + 'leads/slist'
                              );
                              break;
                            case 3:
                              window.open(
                                MYACCOUNT_HOMEBAZAAR.replace(/[']/g, '') + 'complete_profile'
                              );
                              break;
                            case 4:
                              window.open(
                                MYACCOUNT_HOMEBAZAAR.replace(/[']/g, '') + 'properties/add'
                              );
                              break;
                            case 5:
                              window.open(
                                MYACCOUNT_HOMEBAZAAR.replace(/[']/g, '') + 'properties/list'
                              );
                              break;
                            case 6:
                              window.open(
                                MYACCOUNT_HOMEBAZAAR.replace(/[']/g, '') + 'leads/list'
                              );
                              break;
                            case 7:
                              logout();
                              break;
                            default:
                          }
                        }}
                        size={12}>
                    <img width="25px" height="25px" src={image} alt={title} />
                    <CommonHeaderText
                      text={title}
                      variant="span"
                      style={{
                        fontFamily: 'Open Sans, sans-serif',
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#707070',
                      }}
                    />
                  </Grid>)
                );
              })}
          </Grid>
        </div>
      )}
    </Popover>
  </>);
};

const mapStateToProps = state => ({
  headersApiData: state.headerReducer,
});

const mapDispatchToProps = {
  logoutUserApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPopover);
