import { Fragment, useState } from 'react';
import ClassNames from 'classnames';
import styles from './headerDropdown.module.scss';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {
  getUserCitiesFromIp,
  persistUserCities,
} from '../../../components/util';

export default function HeaderDropdown(props) {
  // = { selected, select_items, onSelectCity, root_class }
  const [open, setOpen] = useState(false);

  let userCitiesFromIp = getUserCitiesFromIp();

  const handleClick = event => {
    event.preventDefault();
    setOpen(!open);
  };
  const handleClickAway = () => {
    setOpen(false);
    // alert(2)
  };

  function isBuySellType(select_items_type) {
    return (
      select_items_type?.map(item => item.name).includes('buy') ||
      select_items_type?.map(item => item.name).includes('rent')
    );
  }

  const {
    selected,
    select_items,
    onSelectCity,
    root_class,
    //  module_type
  } = props;
  let selectedItem = !isBuySellType(select_items)
    ? selected
      ? selected
      : userCitiesFromIp
    : selected;
  return (
    <Fragment>
      <div
        data-cy={'header-dropdown-container'}
        className={ClassNames([
          styles.nice_select,
          styles.wide,
          styles.country,
          root_class,
        ])}
        onClick={handleClick}>
          <span className={styles.current}>
            {selectedItem
              ? selectedItem?.name == 'buy'
                ? 'Buy'
                : selectedItem?.name
              : 'All Items'}
          </span>
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={handleClickAway}>
          <ul
            className={styles.list}
            style={{
              display: open ? 'block' : 'none',
              transform: 'scale(1) translateY(0)',
            }}>
            {select_items &&
              select_items.map((item, index) => {
                const { name, id, title } = item;
                return (
                  <li
                    data-cy={'header-dropdown-item-' + name}
                    key={index}
                    className={ClassNames([
                      styles.option,
                      selectedItem && selectedItem?.id === id
                        ? styles.focus
                        : '',
                    ])}
                    onClick={() => {
                      !isBuySellType(select_items)
                        ? persistUserCities({
                            id: id,
                            name: name.toLowerCase(),
                          })
                        : // router.push(`/${module_type}/${name.toLowerCase()}`))
                          '';

                      onSelectCity && onSelectCity(id, name.toLowerCase());
                    }}>
                    {title ? title : name}
                  </li>
                );
              })}
          </ul>
        </ClickAwayListener>
      </div>
    </Fragment>
  );
}
