/* ########## COMPONENT IMPORTS ########## */
import useStickyHeader from '@/components/packages/use-sticky-header';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { connect } from 'react-redux';
import {
  getLeaseTypePropertyCount,
  setCurrentCity,
} from '../../redux/actions/main';
import {
  getSearchPageURL,
  getUserCitiesFromIp,
  gotoPropertyDetailsPage,
  searchURLType,
} from '../util';
import {
  findClientIp,
  persistClientIp,
  logoutUser,
  getQueryParamFromUrl,
} from '../util';
import useWindow from '@/hooks/useWindow';
import Footer from '@/components/Desktop/Footer/Footer';
import StickyMobileFooter from '../Mobile/MobileFooter/Variant2';
import HeaderComponent from '@/components/Desktop/Header';
import StickyHeader from '@/components/Desktop/StickyHeader';
import SearchFilterPopup from '@/components/Mobile/SearchFilterPopup';
import MobileFooter from '@/components/Mobile/MobileFooter';

import useMaterialMediaQuery from '../packages/use-material-media-query';
import useSearchFilter from '@/hooks/useSearchFilter';
const BaseLayout = props => {
  const { moduletype, locations, children } = props;
  const router = useRouter();
  const [currentRoute, setcurrentRoute] = useState(children?.props?.requestUrl)
  const { hasWindow } = useWindow();
  persistClientIp(props);
  // console.log('children requestUrl', children.props.requestUrl)
  const page_Name = props.headersApiData.pageName;
  const { props: pageProps } = children;
  const { footer_data, isMobileView } = pageProps;
  let userCitiesFromIp = getUserCitiesFromIp();
  const [module_type, setModuleType] = useState(moduletype);
  let defaultCity = {
    //TO-CHECK : Removed client ip call
    id: props?.city_id ?? userCitiesFromIp?.id,
    name: props?.city_name ?? userCitiesFromIp?.name,
  };

  const [city, setCity] = useState(defaultCity);
  const searchFilter = useSearchFilter(state => {
    state.module = module_type;
    state.cityId = city?.id ?? 1;
    state.cityName = city?.name ?? 'Mumbai';
  });

  // console.log('userCitiesFromIp', userCitiesFromIp)
  const onModuleSelect = (id, name) => {
    setModuleType(name);
    if (page_Name == 'HomePage') {
      window.location.replace(name == "rent" ? `/${name}/${city.name.toLowerCase()}` :  `/${city.name.toLowerCase()}`);
    } else {
      window.location.replace(name == "rent" ? `/${name}/search` : "/search");
    }
  };

  ////TO-CHECK for update city in home page
  const onCitySelect = (id, name) => {
    setCity({ id: id, name: name });
    props.setCurrentCity({ id: id, name: name });
    searchFilter.settings.cityId = id;
    searchFilter.settings.cityName = name;
    router.push(
      {
        pathname: getSearchPageURL(searchURLType.searchCity, module_type, name),
        // query: { sortBy: 'price' }
      },
      undefined,
      { shallow: true }
    );
  };

  ////  TO-DO : for Builder Page city select
  const onBuilderCitySelect = (id, name) => {
    setCity({ id: id, name: name });
    if (hasWindow) {
      window.location.replace(
        getSearchPageURL(searchURLType.propertiesIn, module_type, name)
      );
    }
  };

  useEffect(() => {
    searchFilter.settings.cityId = city?.id;
    searchFilter.settings.cityName = city?.name;
    setcurrentRoute(router.asPath)
  }, [city, router.asPath]);

  const [filter, setFilter] = useState({ show: false, searchScreen: true });

  const [deletecachedpropertytrigger, setDeleteCachedPropertyTrigger] =
    useState(false);
  const [addwishlisttrigger, setaddwishlisttrigger] = useState(false);
  const [refreshWishlist, setrefreshWishlist] = useState(false);

  const [wishlistpropertydeletedtrigger, setWishlistPropertyDeletedTrigger] =
    useState(false);
  const callWishlistApi = () => {
    setaddwishlisttrigger(!addwishlisttrigger);
    setrefreshWishlist(!refreshWishlist);
  };
  const wishlistPropertyDeletedCall = () => {
    setWishlistPropertyDeletedTrigger(!wishlistpropertydeletedtrigger);
  };
  useEffect(() => {
    if (refreshWishlist) {
      callWishlistApi();
    } else {
      wishlistPropertyDeletedCall();
    }
  }, []);
  const [small] = useMaterialMediaQuery();

  let stickyHeaderHeight  = isMobileView ? 400 : 290 ;
  const [show_sticky_header] = useStickyHeader(stickyHeaderHeight);
  const propertyDetailsClick = (
    property_id,
    property_name,
    location,
    pCount
  ) => {
    if (hasWindow) {
      if (pCount > 1) {
        window.location.href = getSearchPageURL(
          searchURLType.propertiesAt,
          module_type,
          property_name
        );
      } else {
        gotoPropertyDetailsPage({
          module_type: module_type,
          city_name: city?.name,
          property_name: property_name,
          property_id: property_id,
          localityName: location,
        });
      }
    }
  };

  useEffect(() => {
    const logoutRequested =
      getQueryParamFromUrl(router.asPath, 'logout') == '1';
    if (logoutRequested) {
      // logout user
      logoutUser();

    }
  }, []);

  useEffect(() => {
    if (module_type == 'rent') {
      props.getLeaseTypePropertyCount({
        module_type: module_type,
        city_id: city.id,
      });
    }
  }, [module_type, city]);


  return (
    <>
      {page_Name !== '' ? (
        page_Name == 'HomePage' ? (
          <HeaderComponent
            module_type={module_type}
            is_desktop={!isMobileView && !small}
            page_Name={page_Name}
            city={city}
            searchFilter={searchFilter}
            onCitySelect={onCitySelect}
            banner={true}
            show_sticky_header={show_sticky_header}
            onSearchInputClick={
              small
                ? e => {
                    setFilter({ show: true, searchScreen: true });
                    e.stopPropagation();
                  }
                : null
            }
            currentRoute={currentRoute}
            setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
            deletecachedpropertytrigger={deletecachedpropertytrigger}
            addwishlisttrigger={addwishlisttrigger}
            wishlistPropertyDeletedCall={wishlistPropertyDeletedCall}
          />
        ) : (
          <>
            {small && page_Name == 'PDP_Page' ? (
              <>
                {' '}
                <HeaderComponent
                  page_Name="PDP_Page"
                  city={city}
                  searchFilter={searchFilter}
                  module_type={module_type}
                />
              </>
            ) : (!isMobileView && !small && page_Name == 'Builders_Page') ||
              page_Name == 'PDP_Page' ? (
              page_Name != 'PDP_Page' ? (
                <StickyHeader
                  onModuleSelect={onModuleSelect}
                  onCitySelect={onBuilderCitySelect}
                  locations={locations}
                  city={city}
                  module_type={module_type}
                  propertyDetailsClick={propertyDetailsClick}
                  setDeleteCachedPropertyTrigger={
                    setDeleteCachedPropertyTrigger
                  }
                  deletecachedpropertytrigger={deletecachedpropertytrigger}
                  addwishlisttrigger={addwishlisttrigger}
                  wishlistPropertyDeletedCall={wishlistPropertyDeletedCall}
                  pageType={true}
                  static_page={false}
                  is_desktop={true}
                />
              ) : (
                <></>
              )
            ) : (
              small && (
                <HeaderComponent
                  page_Name={page_Name}
                  value={'searchPage'}
                  city={city}
                  searchFilter={searchFilter}
                  module_type={module_type}
                />
              )
            )}
          </>
        )
      ) : !small ? (
        <HeaderComponent
          is_desktop={!isMobileView}
          city={city}
          page_Name={page_Name}
          searchFilter={searchFilter}
          module_type={module_type}
        />
      ) : (
        <HeaderComponent
          searchFilter={searchFilter}
          page_Name={page_Name}
          city={city}
          module_type={module_type}
        />
      )}

      <div style={{ marginTop: '0px' }}>
        {React.cloneElement(children, {
          city: city,
          onCitySelect: onCitySelect,
          addwishlisttrigger: addwishlisttrigger,
          callWishlistApi: callWishlistApi,
          wishlistPropertyDeletedCall: wishlistPropertyDeletedCall,
          wishlistpropertydeletedtrigger: wishlistpropertydeletedtrigger,
        })}
      </div>
      {isMobileView &&
        page_Name !== 'SearchPage' &&
        page_Name !== 'PDP_Page' && (
          <StickyMobileFooter
            onSearchClick={() => setFilter({ show: true, searchScreen: false })}
            setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
          />
        )}

      {page_Name == '' ? (
        <>
          {small || isMobileView ? (
            <>
              <MobileFooter
                data={footer_data}
                module_type={module_type}
                city={city}
              />
            </>
          ) : (
            <Footer data={footer_data} city={city} module_type={module_type} />
          )}
        </>
      ) : (
        <>
          {small || isMobileView ? (
            <>
              <MobileFooter
                pageType={page_Name}
                data={footer_data}
                module_type={module_type}
                city={city}
              />
            </>
          ) : (
            <Footer data={footer_data} city={city} module_type={module_type} />
          )}
        </>
      )}
      {filter.show && (
        <SearchFilterPopup
          city={city}
          moduleType={module_type}
          isOpen={filter.show}
          onCloseClick={() => setFilter({ show: false, searchScreen: true })}
          showSearchScreen={filter.searchScreen}
        />
      )}
      <>
        {show_sticky_header &&
          !isMobileView &&
          !small &&
          (page_Name !== '' ? (
            page_Name == 'HomePage' ? (
              <StickyHeader
                onModuleSelect={onModuleSelect}
                onCitySelect={onCitySelect}
                locations={locations}
                city={city}
                module_type={module_type}
                propertyDetailsClick={propertyDetailsClick}
                is_desktop={true}
                setDeleteCachedPropertyTrigger={setDeleteCachedPropertyTrigger}
                deletecachedpropertytrigger={deletecachedpropertytrigger}
                addwishlisttrigger={addwishlisttrigger}
                wishlistPropertyDeletedCall={wishlistPropertyDeletedCall}
                pageType={true}
              />
            ) : (
              page_Name == 'Builders_Page' && (
                <StickyHeader
                  onModuleSelect={onModuleSelect}
                  onCitySelect={onBuilderCitySelect}
                  locations={locations}
                  city={city}
                  module_type={module_type}
                  propertyDetailsClick={propertyDetailsClick}
                  is_desktop={true}
                  setDeleteCachedPropertyTrigger={
                    setDeleteCachedPropertyTrigger
                  }
                  deletecachedpropertytrigger={deletecachedpropertytrigger}
                  addwishlisttrigger={addwishlisttrigger}
                  wishlistPropertyDeletedCall={wishlistPropertyDeletedCall}
                  pageType={true}
                  static_page={false}
                />
              )
            )
          ) : (
            <StickyHeader
              static_page={true}
              is_desktop={!isMobileView}
              pageType={true}
            />
          ))}
      </>
    </>
  );
};

export const getHomePageData = async ({ ctx }) => {
  const clientIp = findClientIp(ctx.req);

  return { props: { clientIp } };
};

const mapStateToProps = state => ({
  apiData: state.footerReducer,
  headersApiData: state.headerReducer,
  leasePropertyCount: state.LeastPropCountReducer,
  // currentCity:
});

const mapDispatchToProps = {
  getLeaseTypePropertyCount,
  setCurrentCity,
};
export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);
