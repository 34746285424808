import { useEffect } from 'react';
import Modal from 'react-modal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ClassNames from 'classnames';
import Grid from '@mui/material/Grid2';
// import { makeStyles } from '@mui/styles';
// import useMaterialMediaQuery from '@/components/packages/use-material-media-query';
// import CloseIcon from '@mui/icons-material/Close';
import classes from './popupDialog.module.scss';
import { useRouter } from 'next/router';

const PopupDialogBox = props => {
  const {
    modal_content,
    children,
    open,
    close,
    text,
    root_class,
    root_style,
    // isAskQuestion,
    page,
  } = props;
  const router = useRouter();
  function afterOpenModal() {}
  // const [small] = useMaterialMediaQuery();
  useEffect(() => {}, [open]);

  return (<>
    <Modal
      isOpen={open}
      onAfterOpen={afterOpenModal}
      onRequestClose={close}
      className={ClassNames([classes.root, root_class])}
      contentLabel="Modal"
      ariaHideApp={false}
      style={{
        ...root_style,
        overlay: { zIndex: 99999999 },
      }}>
      {text && (
        <Grid
          justifyContent="flex-start" // Add it here :)
          container>
          <Grid size={12}>
            <h5
              style={{
                fontWeight: '700',
                color: '#171717',
                fontSize: '30px',
                textAlign: 'center',
              }}>
              {text}
            </h5>
            <button onClick={close} type="button" className={classes.close}>
              <HighlightOffIcon />
            </button>
          </Grid>
        </Grid>
      )}
      {page !== 'sign-up' && close ? (
        <div
          className={
            router.asPath === '/home-selling-guide#'
              ? classes.closebtnGuide
              : classes.closebtn
          }>
          <div
            className={classes.cancelIcon}
            onClick={close}
            alt="Cancel Icon"
            style={{
              backgroundImage: `url("/static/images/artboardicon.png")`,
              backgroundPosition: `-1780px`,
              backgroundSize: 'cover',
              position: 'relative',
              bottom: '20px',
              width: '33px!important',
              height: '33px!important',
            }}></div>
        </div>
      ) : (
        <div></div>
      )}
      <div style={{ textAlign: 'right' }}>

      </div>
      {modal_content ?? children ?? ''}
    </Modal>
  </>);
};

export default PopupDialogBox;
