import { useState, useEffect } from 'react';

const useUserIpThirdParty = () => {
  const [ipAddress, setIpAddress] = useState(null);

  useEffect(() => {

    const fetchIp = async () => {
      try {
        const response = await fetch('https://geolocation-db.com/json/');
        const res = await response.json();
        if (res?.IPv4) {
          setIpAddress(res.IPv4);
          window.localStorage.setItem('clientIpAddress', res.IPv4);
        }
      } catch (err) {
        console.error('Error fetching IP:', err);
      }
    };

    fetchIp();
  }, []);

  return [ipAddress, setIpAddress];
};

export default useUserIpThirdParty;
