import { KEY_FILTER_QUERY } from '@/hooks/useSearchFilter';
import { emptyItem, emptyObject, emptyArray } from '../../config/LocalStorage';
import base from 'base-x';
export const capitalizeFirstLetter = string => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};

export const splitStringCapitalizeFirstLetter = string => {
  return capitalizeFirstLetter(string.replace('-', ' '));
};

export const numberWithCommas = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const highlightedText = word => {
  return <span className="highlight">{word}</span>;
};

export const logoutUser = (redirect = true) => {
  emptyObject('user');
  emptyItem('access_token');
  //@ TO-DO conadition for window undefined
  if (redirect) window.location.href = '/';
};

/**
 * Retrives the value for a given query parameter from URL or URL path segment.
 *
 *
 * <pre>
 * Example:
 * <code>
 * const url = "http://www.example.com/abc?foo=lorem&bar=&baz" // or just /abc?foo=lorem&bar=&baz
 * const foo = getParameterByName(url, 'foo'); // "lorem"
 * const bar = getParameterByName(url, 'bar'); // "" (present with empty value)
 * const baz = getParameterByName(url, 'baz'); // "" (present with no value)
 * const qux = getParameterByName(url, 'qux'); // null (absent)
 * </code>
 * </pre>
 *
 * @param {string} url
 * @param {string} paramKey
 * @returns query parameter value or null if not found
 */
export const getQueryParamFromUrl = (url, paramKey) => {
  // eslint-disable-next-line no-useless-escape
  const key = paramKey.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + key + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const emptyAllGlobalSelected = key => {
  if (key === 'city') {
    emptyObject(key);
  } else if (key === 'cities') {
    emptyArray(key);
  } else if (key == 'popular_part_id') {
    emptyArray('popular_part_id');
  } else if (key == 'location_id') {
    emptyArray('location_id');
  } else if (key == 'sublocation_id') {
    emptyArray('sublocation_id');
  } else if (key == 'developer_id') {
    emptyArray('developer_id');
  } else {
    emptyArray('popular_part_id');
    emptyArray('location_id');
    emptyArray('sublocation_id');
    emptyArray('developer_id');
  }
};

export const slugify = text => text?.replace(/&/g, 'and');

export const titleFromKebab = (text, delimeter = '-') => {
  let strings = (text ?? '').split(delimeter);
  let title = '';
  strings.forEach(e => {
    title +=
      e.includes('bhk') || e.includes('rk')
        ? `${e.replace('bhk', ' bhk').replace('rk', ' rk').toUpperCase()} `
        : `${capitalizeFirstLetter(e)} `;
  });
  return title.substring(0, title.length - 1);
};

export const gotoPropertyDetailsPage = props => {
  const {
    module_type,
    city_name,
    property_name,
    property_id,
    localityName,
    bhkTypes,
    willReturnUrl = false,
    isResale = false,
  } = props;
  const url = getPropertyDetailsURL({
    module_type,
    city_name,
    property_name,
    property_id,
    location: localityName,
    bhkTypes,
    isResale,
  });
  if (typeof window !== 'undefined' && !willReturnUrl) {
    window.open(url, '_blank');
  } else {
    return url.toLowerCase();
  }
};

export const generatePropertyPath = (
  moduleType,
  cityName,
  propertyName,
  propertyId
) => {
  return getPropertyDetailsURL({
    module_type: moduleType,
    city_name: cityName,
    property_name: propertyName,
    property_id: propertyId,
    location: cityName,
    bhkTypes: '',
    isResale: false,
  });
  // return `/${moduleType.toLowerCase()}/${slugify(
  //   propertyName.toLowerCase().replace(/\s/g, '-')
  // )}-property-${cityName.toLowerCase()}-${propertyId}`;
};

export const gotoPropertyDetailsPageFromSearch = props => {
  const { module_type, city_name, property_name, property_id, localityName } =
    props;
  if (typeof window !== 'undefined') {
    window.open(
      getPropertyDetailsURL({
        module_type,
        city_name,
        property_name,
        property_id,
        location: localityName,
        bhkTypes: '0',
        isResale: false,
      })
    );
  }
};

export const getPropertyDetailsURLForRent = (
  moduleType,
  cityName,
  bhkTypes,
  maxCarpetArea,
  buildingName,
  propertyId,
  location
) => {
  let propertyName = `${maxCarpetArea}-sq-ft-apartment-for-rent-${buildingName}`;
  return getPropertyDetailsURL({
    module_type: moduleType,
    city_name: cityName,
    property_name: propertyName,
    property_id: propertyId,
    location: location,
    bhkTypes: bhkTypes,
  });
};

export const searchURLType = {
  dealOfWeek: 'deal-of-week',
  discountedDeals: 'discounted-deals',
  recommendedProjects: 'recommended-projects-in',
  propertiesAt: 'properties-at',
  searchCity: 'search-city',
  propertiesFor: 'properties-for',
  propertiesIn: 'properties-in',
  search: 'search',
};

export const moduleTypeList = {
  buy: 'buy',
  resale: 'buy',
  rent: 'rent',
};

export const getSearchPageURL = (
  type = searchURLType.search,
  moduleType,
  urlSlug = 'mumbai',
  searchHash
) => {
  let url = '';
  if (moduleType === moduleTypeList.rent) {
    url += `/${moduleType}`;
  }
  switch (type) {
    case searchURLType.dealOfWeek:
      url += `/deal-of-the-week-in-${slugify(urlSlug.toLowerCase())}`;
      break;
    case searchURLType.discountedDeals:
      url += `/discounted-deals-in-${slugify(urlSlug.toLowerCase())}`;
      break;
    case searchURLType.recommendedProjects:
      url += `/recommended-projects-in-${slugify(urlSlug.toLowerCase())}`;
      break;
    case searchURLType.propertiesAt:
      url += `/properties-at-${urlSlug.replaceAll(' ', '-')}`;
      break;
    case searchURLType.searchCity:
      url += `/${slugify(urlSlug.toLowerCase())}`;
      break;
    case searchURLType.propertiesIn:
      url += `/properties-in-${slugify(urlSlug.toLowerCase())}`;
      break;
    default:
      url += `/search${
        searchHash ? '?' + KEY_FILTER_QUERY + '=' + searchHash : ''
      }`;
  }
  return url;
};

export const getPropertyDetailsURL = (props) => {
  const {
    module_type,
    city_name,
    property_name,
    property_id,
    location,
    bhkTypes,
    isResale = false,
  } = props;
  let url = '';

  if (module_type && city_name && property_name && property_id) {
    let propertyName = `${
      bhkTypes ? `${bhkTypes.toLowerCase().replace(/\s/g, '')}-` : ''
    }${slugify(
      property_name
        .toLowerCase()
        .replace(/\.-/g, '-') // Replace .- with a single hyphen
        .replace(/\./g, '-') // Replace remaining periods with hyphens
        .replace(/[']/g, '-') // Replace apostrophes with hyphens
        .replace(/\s/g, '-') // Replace spaces with hyphens
        .replace(/--+/g, '-') // Replace multiple hyphens with a single hyphen 
        .replace(/’/g, '-') // Replace apostrophes with hyphens 
        
    )}`;

    let locName =
  location?.l_name !== undefined
    ? location.l_name
        .toLowerCase()
        .replace(/\.-/g, '-') // Replace .- with a single hyphen
        .replace(/\./g, '-') // Replace remaining periods with hyphens
        .replace(/[']/g, '-') // Replace apostrophes with hyphens
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/-+/g, '-') // Replace multiple hyphens with a single hyphen
        .replace(/^-+|-+$/g, '') // Trim leading and trailing hyphens
    : (location ?? '')
        .toLowerCase()
        .replace(/\.-/g, '-') // Replace .- with a single hyphen
        .replace(/\./g, '-') // Replace remaining periods with hyphens
        .replace(/[']/g, '-') // Replace apostrophes with hyphens
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/-+/g, '-') // Replace multiple hyphens with a single hyphen
        .replace(/^-+|-+$/g, ''); // Trim leading and trailing hyphens


    if (module_type.toLowerCase() !== 'buy' || isResale) {
      const moduleString = isResale ? '' : `${module_type.toLowerCase()}/`;
      url = `/${moduleString}${
        isResale ? 'resale-' : ''
      }${propertyName}-property${locName ? `-${locName}` : ''}${
        city_name ? `-${city_name.toLowerCase()}` : ''
      }-${property_id}`;
    } else {
      url = `/${isResale ? 'resale-' : ''}${propertyName}${
        locName ? `-${locName}` : ''
      }-${property_id}`;
    }
  }

  return url.toLowerCase();
};

export const persistUserCitiesFromIp = cities => {
  if (typeof window !== 'undefined') {
    if (!localStorage.getItem('userCitiesFromIp')) {
      localStorage.setItem('userCitiesFromIp', JSON.stringify(cities));
    }
  }
};

export const persistUserCities = cities => {
  if (typeof window !== 'undefined')
    localStorage.setItem('userCitiesFromIp', JSON.stringify(cities));
};

export const persistClientIp = props => {
  if (typeof window !== 'undefined') {
    localStorage.setItem('clientIpAddress', props.clientIp || '1.1.1.1');
  }
};

export const getUserCitiesFromIp = () => {
  return (
    typeof localStorage != 'undefined' &&
    JSON.parse(localStorage.getItem('userCitiesFromIp'))
  );
};

export const findClientIp = req => {
  const forwarded = req.headers['x-forwarded-for'];
  const ip = forwarded
    ? forwarded.split(/, /)[0]
    : req.connection.remoteAddress;

  return ip;
};

export const onRouteChanged = (moduletype, url, sellBTNurl) => {
  if (typeof window !== 'undefined') {
    if (moduletype == 'sell') {
      window.location.href = sellBTNurl;
    } else {
      window.location.href =  moduletype == "rent" ? `/${slugify(moduletype)}/${slugify(url)}` : '/' + slugify(url);
    }
  }
};
export const gotoHome = () => {
  if (typeof window !== 'undefined' && window.history.length > 1) {
    window.history.back();
  } else {
    // If there's no previous page, you can redirect to the home page using window.location.href
    window.location.href = '/';
  }
};

export const resetCorruptedLocalStorage = () => {
  if (typeof localStorage === 'undefined') return;

  let defaultState = [
    { name: 'clientIpAddress', value: '' },
    { name: 'sublocation_id', value: '[]' },
    { name: 'location_id', value: '[]' },
    { name: 'developer_id', value: '[]' },
    { name: 'userCitiesFromIp', value: '{"id":1,"name":"Mumbai"}' },
    { name: 'cities', value: '[{"id":1,"name":"Mumbai"}]' },
    { name: 'popular_part_id', value: '[]' },
    { name: 'city', value: '{"id":1,"name":"Mumbai"}' },
  ];

  for (let i = 0; i < defaultState.length; i++) {
    let obj = defaultState[i];
    let propName = obj.name;
    let value = localStorage.getItem(propName);
    if (value === 'undefined' || value == '[]' || value === '{}') {
      localStorage.setItem(propName, obj.value);
    }
  }
};

export const slugifyURL = text => {
  return text
    .replace(/&/g, '-and-')
    .replace(/[^a-zA-Z0-9-/]/g, '-')
    .replace(/-+/g, '-');
};

// //// @TO-DO this section is not abelable in sumit-branch
export const getPropertyDetailsURLForRentResale = (
  moduleType,
  cityName,
  bhkTypes,
  maxCarpetArea,
  buildingName,
  propertyId,
  location,
  isResaleProp,
  isWishlistRentReasale,
  sqft,
  isResale
) => {
  let propertyName = `${
    maxCarpetArea ?? ''.replace(',', '')
  }-sq-ft-apartment-for-${isResale ? 'resale' : moduleType}-${buildingName}`;
  if (isWishlistRentReasale) {
    let propertyNameResale = `${parseInt(
      (maxCarpetArea ?? sqft).replace(',', '')
    )}-sq-ft-apartment-for-resale-${buildingName
      .toLowerCase()
      .split('resale in')
      .pop()}`;
    // TO-DO : in rent maxCarpetArea in undefind so we use sqft
    let propertyNameRent = `${parseInt(
      (sqft ?? '').replace(',', '')
    )}-sq-ft-apartment-for-rent-${buildingName
      .toLowerCase()
      .split('rent in')
      .pop()}`;
    return getPropertyDetailsURL({
      module_type: moduleType,
      city_name: cityName,
      property_name: isResaleProp ? propertyNameResale : propertyNameRent,
      property_id: propertyId,
      location: location,
      bhkTypes: bhkTypes,
      isResale: isResaleProp,
    });
  } else {
    return getPropertyDetailsURL({
      module_type: moduleType,
      city_name: cityName,
      property_name: propertyName,
      property_id: propertyId,
      location: location,
      bhkTypes: bhkTypes,
      isResale: isResale,
    });
  }
};

export const isMobileView = req => {
  let isMobileViewReq = (req && req.headers['user-agent'])?.match(
    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
  );

  //Returning the isMobileView as a prop to the component for further use.
  return Boolean(isMobileViewReq);
};

const base62 = base(
  'abcdefghijklmnopqrstuvwxyz0123456789'
);
export const encrypt = (state) => {
  const stateObj = Object.assign({}, state);
  for (const key of Object.keys(state)) {
    if (Array.isArray(stateObj[key]) && stateObj[key].length === 0) {
      delete stateObj[key]; // Get rid of empty array values
    }
  }
  const encoder = new TextEncoder();
  return base62.encode(encoder.encode(JSON.stringify(stateObj)))
}
export const decrypt = (encodedQuery) => {
  try {
    if (!encodedQuery) return null; // Handle empty input
    const jsonString = new TextDecoder('utf-8', { fatal: true }).decode(
      base62.decode(encodedQuery)
    );
    return JSON.parse(jsonString);
  } catch (ex) {
    console.error('Decryption error:', ex);
    if (ex.message !== "Non-base36 character") {
      // Consider more graceful handling than immediate logout
      logoutUser();
    }
    return null;
  }
}