import React, { forwardRef } from 'react';
import { Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'classnames';

const PREFIX = 'Section';

const classes = {
  root: `${PREFIX}-root`,
  bgSecondary: `${PREFIX}-bgSecondary`,
  borderB: `${PREFIX}-borderB`,
  head: `${PREFIX}-head`,
  heading: `${PREFIX}-heading`,
};

// Styled MUI Paper without forwardRef (MUI handles ref internally)
const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'rootClass',
})(({ theme }) => ({
  [`&.${classes.root}`]: {
    fontFamily: 'Open Sans, sans-serif',
    padding: '0',
    border: 'none',
    [theme.breakpoints.down('md')]: {
      padding: '1em',
    },
    '& *': {
      fontFamily: 'Open Sans, sans-serif',
    },
  },

  [`&.${classes.bgSecondary}`]: {
    backgroundColor: '#F8F8FF',
    borderBottom: '1px solid #d2deeb',
  },
  [`&.${classes.borderB}`]: {
    borderBottom: '1px solid #d2deeb',
  },

  [`& .${classes.head}`]: {
    display: 'flex',
    flexFlow: 'row nowrap',
    gap: '0.5rem',
  },

  [`& .${classes.heading}`]: {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

// Section component with proper ref forwarding
const Section = forwardRef(function Section(props, ref) {
  const { rootClass, title, variant, icon, childRootStyle = {}, children } = props;

  return (
    <StyledPaper
      ref={ref} // Forward ref correctly
      sx={{ padding: '20px' }}
      className={clsx(
        classes.root,
        variant === 'secondary' && classes.bgSecondary,
        variant === 'borderB' && classes.borderB,
        rootClass
      )}
      square
      variant="outlined"
      style={childRootStyle}
      elevation={0}
    >
      {(icon || title) && (
        <div className={classes.head}>
          {icon}
          <Typography
            sx={{ marginBottom: '10px', fontFamily: "'Open Sans', sans-serif" }}
            className={classes.heading}
          >
            {title}
          </Typography>
        </div>
      )}
      <div>{children}</div>
    </StyledPaper>
  );
});

export default Section;
