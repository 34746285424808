import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

import {
  InputBase,
  NativeSelect,
  Slider,
  Typography,
} from '@mui/material';
import { useState } from 'react';

const PREFIX = 'RangeSlider';

const classes = {
  root: `${PREFIX}-root`,
  thumb: `${PREFIX}-thumb`,
  active: `${PREFIX}-active`,
  track: `${PREFIX}-track`,
  mark: `${PREFIX}-mark`,
  rail: `${PREFIX}-rail`,
  input: `${PREFIX}-input`,
  icon: `${PREFIX}-icon`
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    height: 8,
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      marginLeft: '2%',
      marginRight: '2%',
    },
  },

  [`& .${classes.thumb}`]: {
    height: 24,
    width: 24,
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,0.08)',
    boxShadow: '0px 0px 15px -2px rgba(0,0,0,0.1);',
    marginTop: '-2px',
    marginLeft: '0!important',
    '& + .MuiSlider-thumb': {
      marginLeft: -12,
    },
    '&:focus, &:hover': {
      boxShadow: '0px 0px 0px 4px rgb(25 118 210 / 16%)',
    },
    '&$active': {
      boxShadow: '0px 0px 0px 8px rgb(25 118 210 / 16%)',
    },
  },

  [`& .${classes.active}`]: {},

  [`& .${classes.track}`]: {
    height: 6,
    borderRadius: 4,
  },

  [`& .${classes.mark}`]: {
    display: 'none',
  },

  [`& .${classes.rail}`]: {
    height: 6,
    borderRadius: 4,
  },

  [`& .${classes.input}`]: {
    borderRadius: 4,
    fontSize: 16,
    padding: '0.4rem 2rem 0.4rem 0.6rem !important',
    border: '1px solid #ced4da',
    '&:focus': {
      borderRadius: 4,
    },
    fontWeight: 'bold',
  },

  [`& .${classes.icon}`]: {
    marginRight: '0.5rem',
  }
}));

const StyledSlider = Slider;

const StyledInput = InputBase;

const StyledSelect = NativeSelect;

function RangeSlider(props) {
  const {
    values,
    formatLabel,
    onRangeChanged,
    defaultRange,
    inclusiveMin = true,
    inclusiveMax = true,
    showEmpty = false,
    emptyMinValue = 0,
    emptyMaxValue = 0,
  } = props;
  const min = values[0];
  const max = values[values.length - 1];

  const [value, setValue] = useState(() => {
    let minValue = values[0];
    let maxValue = values[values.length - 1];
    if (
      Array.isArray(defaultRange) &&
      defaultRange.length === 2 &&
      Number.isFinite(defaultRange[0]) &&
      Number.isFinite(defaultRange[1]) &&
      (defaultRange[0] !== 0 || defaultRange[1] !== 0)
    ) {
      minValue = defaultRange[0];
      maxValue = defaultRange[1];
    }
    return minValue <= maxValue ? [minValue, maxValue] : [maxValue, minValue];
  });
  let getLabel = arg => arg;
  if (formatLabel && typeof formatLabel === 'function') {
    getLabel = formatLabel;
  }
  const handleValueChange = (selectedMin, selectedMax, committed) => {
    let newValue;
    let minVal = selectedMin,
      maxVal = selectedMax;

    if (selectedMax === min && !inclusiveMin) {
      maxVal = values[1];
    }
    if (selectedMin === max && !inclusiveMax) {
      minVal = values[values.length - 2];
    }
    if (minVal > maxVal) {
      newValue = [maxVal, minVal];
    } else {
      newValue = [minVal, maxVal];
    }
    setValue(newValue);
    if (committed && onRangeChanged && typeof onRangeChanged === 'function') {
      onRangeChanged(newValue[0], newValue[1]);
    }
  };

  const handleMinSelectChange = e => {
    if (e.target.value === '') {
      handleValueChange(emptyMinValue, value[1], true);
      return;
    }
    handleValueChange(Number.parseInt(e.target.value), value[1], true);
  };
  const handleMaxSelectChange = e => {
    if (e.target.value === '') {
      handleValueChange(value[0], emptyMaxValue, true);
      return;
    }
    handleValueChange(value[0], Number.parseInt(e.target.value), true);
  };

  return (
    (<Root>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }} className={classes.flexRowSpaceBetween}>
        <Typography className="left" component="span">
          {getLabel(min)}
        </Typography>
        <Typography className="right" component="span">
          {getLabel(max)}
        </Typography>
      </div>
      <StyledSlider
        min={min}
        max={max}
        marks={values.map(value => ({ value }))}
        step={null}
        value={value}
        
      valueLabelFormat={value => getLabel(value)}
        onChange={(e, [minValue, maxValue]) =>
          handleValueChange(minValue, maxValue)
        }
        onChangeCommitted={(e, [minValue, maxValue]) =>
          handleValueChange(minValue, maxValue, true)
        }
        classes={{
          root: classes.root,
          thumb: classes.thumb,
          active: classes.active,
          track: classes.track,
          mark: classes.mark,
          rail: classes.rail
        }} />
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }} className={classes.flexRowSpaceBetween}>
        <StyledSelect
          value={!showEmpty ? value[0] : defaultRange[0] ?? ''}
          input={<StyledInput
            classes={{
              input: classes.input
            }} />}
          IconComponent={ExpandMoreIcon}
          onChange={handleMinSelectChange}
          classes={{
            icon: classes.icon
          }}>
            
          {values.map((item, i) => {
            if (i === values.length - 1 && !inclusiveMax) return;
            return (
              <option key={item} value={item}>
                {getLabel(item)}
              </option>
            );
          })}
        </StyledSelect>
        <StyledSelect
          value={!showEmpty ? value[1] : defaultRange[1] ?? ''}
          input={<StyledInput
            classes={{
              input: classes.input
            }} />}
          IconComponent={ExpandMoreIcon}
          onChange={handleMaxSelectChange}
          classes={{
            icon: classes.icon
          }}>
          {values.map((item, i) => {
            if (i === 0 && !inclusiveMin) return;
            return (
              <option key={item} value={item}>
                {getLabel(item)}
              </option>
            );
          })}
        </StyledSelect>
      </div>
    </Root>)
  );
}

export default RangeSlider;
