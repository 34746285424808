import { React, useState, useEffect } from 'react';
import ClassNames from 'classnames';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import CommonHeaderText from '../../../../packages/common-header-text';
import OtpInput from 'react-otp-input';
import {
  FORGET_PASSWORD,
  VERIFY_OTP,
} from '../../../../../config/EndpointHelper';
import { headers } from '../../../../../config/HeaderHelper';
import { API_ROOT } from '../../../../../config';
import axios from 'axios';
import DataHelper from '../../../../../config/DataHelper';
import CircularProgress from '@mui/material/CircularProgress';
import classes from './forgetPassoword.module.scss';
import useAccessToken from '@/components/packages/use-access-token';

const ForgotPasswordMob = props => {
  const { root_style, root_class, onChange, showReset, setToken, hideBackBtn } =
    props;
  const [flag, setFlag] = useState(false);
  const [otp, setOTP] = useState('');
  const [phn, setPhn] = useState('');
  const [error, setError] = useState('');
  const [otpError, setOtpError] = useState();
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [sucessMsg, setSucessMsg] = useState('');
  const [timer, setTimer] = useState(60);
  const [resendCount, setResendCount] = useState(
    Number(localStorage.getItem('resendCount')) || 0
  );
  const [isFormSubmitted, setIsFormSubmitter] = useState(false);
  const [access_token] = useAccessToken();
  const isAuthenticated = Boolean(access_token);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Load the resend count from localStorage
      const storedResendCount =
        Number(localStorage.getItem('resendCount')) || 0;
      setResendCount(storedResendCount);
      // Check last resend timestamp
      const lastResendTimestamp = localStorage.getItem('lastResendTimestamp');
      if (lastResendTimestamp) {
        const elapsedTime = Date.now() - parseInt(lastResendTimestamp, 10);
        if (elapsedTime > 60 * 60 * 1000) {
          // Reset resend count if more than 1 hour has passed
          setResendCount(0);
          localStorage.setItem('resendCount', 0); // Reset local storage count
          localStorage.removeItem('lastResendTimestamp'); // Remove timestamp from local storage
        }
      }
    }
  }, []);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prev => prev - 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);
  const sendOtp = async () => {
    setTimer(60);
    if (resendCount <= 3) {
      setResendCount(prev => {
        const newCount = prev + 1;
        localStorage.setItem('resendCount', newCount.toString());
        if (newCount === 1) {
          localStorage.setItem('lastResendTimestamp', Date.now().toString());
        }
        return newCount;
      });
    }

    let payload = {
      phone: phn,
      page: 'resetinput',
    };
    await axios
      .post(API_ROOT + FORGET_PASSWORD, DataHelper(payload), {
        headers: headers,
      })
      .then(async response => {
        if (response.status == 200) {
          setLoading(false);
          setFlag(true);
          setOtpError("");
          setSucessMsg(
            sucessMsg ? 'OTP resent successfully' : 'OTP sent successfully'
          );
        } else if (response.status == 202) {
          setErrMsg(response.message);
        }
      })
      .catch(error_forgotPass => {
        if (error_forgotPass.response.status == 400) {
          setErrMsg(error_forgotPass.response.data.message);
          setLoading(false);
        }
      });
  };

  const verifyOtp = async () => {
    let payload = {
      phone: phn,
      otp: otp,
    };
    await axios
      .post(API_ROOT + VERIFY_OTP, DataHelper(payload), {
        headers: headers,
      })
      .then(async response => {
        if (response.status == 200) {
          setLoading(false);
          setSucessMsg(response.data.message);
          setToken(response.data.token);
          showReset();
        } else if (response.status == 202) {
          setErrMsg(response.message);
        }
      })
      .catch(error_forgotPass => {
        setLoading(false);
        if (error_forgotPass.response.status == 409) {
          setOtpError(error_forgotPass.response.data.message);
          setLoading(false);
        }
      });
  };

  const verify = () => {
    if (otp == '' || otp.length !== 6) {
      setOtpError('Please enter the OTP');
    } else {
      setLoading(true);
      verifyOtp();
    }
  };

  const handleSubmit = () => {
   if(resendCount > 2 ){
      setIsFormSubmitter(true);
      return 
    }
    if (phn == '') {
      setError('Please Enter your Mobile Number');
    } else {
      setLoading(true);
      sendOtp();
    }
  };

  return (
    <>
      <div
        className={ClassNames([classes.root, root_class])}
        style={root_style}>
        <>
          {flag == false ? (
            <Grid container style={{ padding: '38px' }}>
              <Grid size={12}>
                <CommonHeaderText
                  text={`Forgot Password`}
                  variant="h5"
                  style={{
                    color: '#171717',
                    fontSize: '28px',
                    fontFamily: 'Open Sans, sans-serif',
                    fontWeight: '400',
                    textAlign: 'left',
                  }}
                />
                <CommonHeaderText
                  text={
                    <span>
                      Please enter the mobile number associated with your
                      account and We will send you OTP to reset your password.
                    </span>
                  }
                  variant="h5"
                  style={{
                    paddingTop: '12px',
                    color: '#171717',
                    fontSize: '14px',
                    fontFamily: 'Open Sans',
                    fontWeight: '400',
                    textAlign: 'left',
                  }}
                />
                <form className={classes.loginFormpd}>
                  <div
                    className={classes.textInput}
                    style={{ marginBottom: '0' }}>
                    <TextField
                      data-cy="Mobile"
                      name="Mobile"
                      id="Mobile"
                      label="Mobile No"
                      value={phn}
                      variant="outlined"
                      onChange={e => {
                        setPhn(e.target.value);
                        setErrMsg('');
                        setError('');
                      }}
                      error={error}
                      style={{ width: '100%' }}
                      helperText={error}
                    />
                  </div>

                  {loading && (
                    <div
                      style={{
                        marginTop: '20px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      <CircularProgress />
                    </div>
                  )}

                  {errMsg != '' && (
                    <h5 className="error-text" style={{ marginTop: '15px' }}>
                      {errMsg}
                    </h5>
                  )}

                  {!isAuthenticated && resendCount > 2 && isFormSubmitted && (
                    <h5 className="error-text-upd">
                      <img
                        src="/static/images/error-form.svg"
                        alt="error-form"
                      />
                      {' Resend otp limit reached. try again after an hour.'}
                    </h5>
                  )}

                  <div className={classes.loadingButtonWrapper}>
                    <Button
                      className={classes.formSubmit}
                      variant="contained"
                      disabled={
                        !isAuthenticated && resendCount > 2 && isFormSubmitted
                      }
                      style={{
                        backgroundColor:
                          !isAuthenticated && resendCount > 2 && isFormSubmitted
                            ? '#B0B0B0'
                            : '',
                        color:
                          !isAuthenticated && resendCount > 2 && isFormSubmitted
                            ? '#FFFFFF'
                            : '',
                        cursor:
                          !isAuthenticated && resendCount > 2 && isFormSubmitted
                            ? 'not-allowed'
                            : '',
                      }}
                      onClick={handleSubmit}>
                      Reset Password
                    </Button>
                  </div>
                  {!hideBackBtn && (
                    <CommonHeaderText
                      text={
                        <span
                          onClick={() => onChange()}
                          className={classes.specialText1}>
                          <img
                            data-cy="arrow-round-forward"
                            width="18px"
                            height="18px"
                            src="/static/images/ionic-ios-arrow-round-backward-back.svg"
                            alt="arrow-round-backward"
                            style={{ marginRight: '13px' }}
                          />{' '}
                          Back
                        </span>
                      }
                      variant="h5"
                      style={{
                        color: '#171717',
                        fontSize: '14px',
                        fontFamily: 'Open Sans, sans-serif',
                        fontWeight: '400',
                        textAlign: 'center',
                        padding: '3px',
                      }}
                    />
                  )}
                </form>
              </Grid>
            </Grid>
          ) : (
            <Grid container style={{ padding: '30px' }}>
              <Grid size={12}>
                <CommonHeaderText
                  text={`Forgot Password`}
                  variant="h5"
                  style={{
                    color: '#171717',
                    fontSize: '28px',
                    fontFamily: 'Open Sans, sans-serif',
                    fontWeight: '400',
                    textAlign: 'left',
                  }}
                />
                <CommonHeaderText
                  text={
                    <div>
                      <Grid className={classes.otpText}>
                        Enter OTP to verify.{' '}
                      </Grid>

                      <span className={classes.codeDetails}>
                        {' '}
                        6 digit code sent to mobile number {phn}
                      </span>
                      <Button
                        className={classes.changeBtn}
                        onClick={() => setFlag(false)}>
                        [change]
                      </Button>
                    </div>
                  }
                  variant="h5"
                  style={{
                    paddingTop: '12px',
                    color: '#171717',
                    fontSize: '14px',
                    fontFamily: 'Open Sans',
                    fontWeight: '400',
                    textAlign: 'left',
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '20PX',
                  }}>
                  <OtpInput
                    numInputs={6}
                    value={otp}
                    onChange={setOTP}
                    separator={false}
                    inputStyle={{
                      marginRight: '10px',
                      width: '40px',
                      height: '50px',
                      fontSize: '20px',
                      border: '1px solid #707070',
                    }}
                  />
                </div>
                <Grid className={classes.resendOtp}>
                {
                resendCount > 2 ? (
                  <CommonHeaderText
                    text={
                      <span className={classes.specialText} style={{ color: 'gray' }}>
                        You’ve reached the resend limit. Please try again in an hour.
                      </span>
                    }
                    variant="h5"
                    style={{
                      color: '#171717',
                      fontSize: '12px',
                      fontFamily: 'Open Sans',
                      fontWeight: '700',
                      textAlign: 'center',
                    }}
                  />
                ) : timer > 0 ? (
                  <CommonHeaderText
                    text={
                      <span className={classes.specialText}>
                        Resend OTP in {timer}s
                      </span>
                    }
                    variant="h5"
                    style={{
                      color: '#171717',
                      fontSize: '14px',
                      fontFamily: 'Open Sans',
                      fontWeight: '700',
                      textAlign: 'center',
                    }}
                  />
                ) : (
                  <CommonHeaderText
                    text={<span onClick={sendOtp}>Resend OTP</span>}
                    variant="h5"
                    style={{
                      color: '#028CF2',
                      fontSize: '14px',
                      fontFamily: 'Open Sans',
                      fontWeight: '700',
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                  />
                )}
                </Grid>
                {sucessMsg != '' && (resendCount <= 3 || isAuthenticated  )&& (
                  <h5 className="success-text" style={{ marginTop: '15px' }}>
                    {sucessMsg}
                  </h5>
                )}

                {otpError != '' && <h5 className="error-text">{otpError}</h5>}

                {loading && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    <CircularProgress />
                  </div>
                )}

                <div className={classes.loadingButtonWrapper}>
                  <Button
                    className={classes.formSubmit}
                    variant="contained"
                    onClick={verify}>
                    Verify
                  </Button>
                </div>
                {!hideBackBtn && (
                  <form className={classes.loginFormpd1}>
                    <CommonHeaderText
                      text={
                        <span
                          onClick={() => {
                            setFlag(false);
                            setOtpError('');
                          }}
                          className={classes.specialText1}>
                          <img
                            data-cy="arrow-round-forward"
                            width="18px"
                            height="18px"
                            src="/static/images/ionic-ios-arrow-round-backward-back.svg"
                            alt="arrow-round-backward"
                            style={{ marginRight: '13px' }}
                          />{' '}
                          Back
                        </span>
                      }
                      variant="h5"
                      style={{
                        color: '#171717',
                        fontSize: '14px',
                        fontFamily: 'Open Sans, sans-serif',
                        fontWeight: '400',
                        textAlign: 'center',
                        padding: '3px',
                      }}
                    />
                  </form>
                )}
              </Grid>
            </Grid>
          )}
        </>
      </div>
    </>
  );
};

export default ForgotPasswordMob;
