import React, { Fragment, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid2';
import Toolbar from '@mui/material/Toolbar';
import SidebarMainMenu from '@/components/packages/main-menu';
import useUserInfo from '@/components/packages/use-user-info';
import { MYACCOUNT_HOMEBAZAAR } from '@/config/index';
import { Box } from '@mui/material';

const styles = {
  root: {
    display: 'flex',
    color: '#fff',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  marginX: {
    padding: '0 20px',
    width: '100%',
  },
  toolbarWrapper: {
    padding: '0 10px',
    '@media(max-width: 600px)': {
      toolbarWrapper: {
        height: '51px!important',
      }
    },
    height: 'auto',
    boxShadow: 'none !important',
    transition: 'all 0.3s ease-in-out',
    '& .MuiToolbar-regular': {
      minHeight: '59px!important',
      '@media(max-width: 768px)': {
        minHeight: '40px!important',
      },
    },
    '& .MuiToolbar-gutters': {
      padding: '0px 20px',
      marginTop: '0px',
      '@media(max-width: 768px)': {
        padding: '0px 8px',
        marginTop: '-9px',
      },
    },
    '& .toolbarWrapper > div:first-of-type': {
      '@media(max-width: 768px)': {
        padding: '0 10px',
      },
    },
    '& .toolbarWrapper > div:last-child': {
      '@media(max-width: 768px)': {
        height: '40px',
        marginTop: '3px',
        padding: '0px 8px',
      },
      height: '56px',
      padding: '0px 20px',
      marginTop: '-1px',
    },
  },
  sellProperty: {
    '& a': {
      color: '#333333',
      fontWeight: 500,
      fontSize: '14px',
    },
    '@media(max-width: 960px) ': {
      display: 'block',
    },
    '@media(max-width: 959px) ': {
      display: 'none',
    },
    color: '#333333',
    border: '1px solid #dedede',
    borderRadius: 0,
    backgroundColor: '#fff',
    marginRight: '5px',
    cursor: 'pointer',
    position: 'relative',
    height: '39px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',  // Using hover effect
    },
  },
  verticalLine: {
    height: '100%',
    borderLeft: '1px solid #ddd',
    position: 'absolute',
    left: '34px',
    '@media(max-width: 1366px)': {
      display: 'none',
    },
    '@media(max-width: 960px)': {
      display: 'none',
    },
  },
  postDetails: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px',

    // Styles for the img tag
    '& img': {
      marginRight: '10px',
      '@media(max-width: 960px)': {
        display: 'none',
      },
      ' @media(max-width: 1366px)': {
        display: 'none',
      },
      '@media(max-width: 1024px)': {
        display: 'none',
        // margin-right: 0px;
      }
    },

    // Styles for the a tag
    '& a': {
      '@media(max-width: 1024px)': {
        display: 'block',
      }
    },
  },
  sidemenuButton: {
    marginTop: '1px',
    padding: '4px 0px',
    // Responsive style for max-width: 600px
    '@media (max-width: 600px)': {
      marginTop: '8px',  // Apply margin-top: 8px on small screens (600px and below)
    },
  },
  filterButtonsContainer: {
    border: '1px solid #0056b21a',
    padding: '9px 20px', // Default padding
    boxShadow: '0px 4px 20px #0056b217',
    backgroundColor: '#f2f5fc',
    flexWrap: 'nowrap',

    // Media query for screens smaller than 1280px
    '@media (max-width: 1280px)': {
      padding: '9px 30px', // Adjust padding on smaller screens
    },
  }
}

const Navbar = props => {
  const {
    logo,
    navbar_buttons,
    city_search,
    sidebar_button,
    menu_items,
    all_filter_buttons,
    sidebar_right_icons,
    sidebar_root_class,
    position,
    is_open,
    pageType,
    small,
    large,
    is_desktop,
    isJsload
  } = props;

  const [open, setOpen] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [user_info] = useUserInfo();

  /* Function to Check if user is Logged In */
  function isUserInfo(user_info_login) {
    // user_info object is empty then user is not logged in
    if (user_info_login == undefined) {
      return true;
    }
    return Object.keys(user_info_login).length === 0;
  }

  useEffect(() => {
    if (!showBanner) {
      setShowBanner(true);
    }
  }, [showBanner]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  const renderSideMenuBar = () => {
    return ReactDOM.createPortal(
      <SidebarMainMenu
        root_class={sidebar_root_class}
        handleClick={handleClick}
        handleClickAway={handleClickAway}
        menuitems={menu_items}
        open_control={open}
        sidebar_right_icons={sidebar_right_icons}
      />,
      document.body
    );
  };
  // const [isJsload, setisJsload] = useState(false);
  // useEffect(() => {
  //   setisJsload(true);
  // }, []);

  return (
    (<Fragment>
      <AppBar
        sx={{
          ...(all_filter_buttons ? {} : styles.toolbarWrapper),
          '--Paper-shadow': 'none!important',
          background: '#F9F9F9',
          borderBottom: pageType ? '1px solid #D9E5F4' : 'none',
          boxShadow: 'none',
        }}
        position={position}>
        <Toolbar sx={styles.toolbar}>
          <Grid
            sx={all_filter_buttons ? styles.marginX : {}}
            justifyContent="space-between" // Add it here :)
            alignItems="center"
            spacing={3}
            style={{ width: '100%', paddingLeft: '0px', paddingRight: '0px', marginTop: '-1px', display: 'flex', flexWrap: 'nowrap', alignItems:"center"}}
            container>
            <Grid
              sx={
                isJsload
                  ? {
                    // marginTop: small ? '12px' : large ? '8px' : '0px',
                    position: large ? 'relative' : undefined
                  }
                  : {
                      // marginTop: '10px!important',
                      position: city_search? 'absolute' :'relative',
                    }
              }
              size={{
                xs: 12,
                sm: 3,
                md: 2,
                lg: 2,
                xl: 2
              }}
              style={{marginTop:small? '13px':'' }}
              >
              <Grid sx={{display:'flex', justifyContent:'left'}}>{logo}</Grid>
            </Grid>
            {city_search && (
              <Grid
                container
                size={{
                  xs: 12,
                  sm: 6,
                  md: 5,
                  lg: 4,
                  xl: 4
                }}
                sx={
                  isJsload
                    ? { width: '35%', marginLeft: '-2px' }
                    : { marginLeft: '15.5rem' }
                }
              >
                <Grid sx={{
                    height: '35px',
                    '& .module-type-grid': {
                      '& > div': {
                        height: '35px',
                      },
                      // Use sx for breakpoints handling
                      '@media (max-width: 1280px)': {
                        width: '20%',
                      },
                      '@media (min-width: 1280px)': {
                        width: '20%',
                      },
                      '@media (min-width: 1920px)': {
                        width: '20%',
                      },
                      '@media (max-width: 1024px)': {
                        width: '17% !important', // Use !important only if needed
                      },
                      '@media (max-width: 768px)': {
                        width: '17%',
                      },
                    },
                    '& .city-grid': {
                      '& > div': {
                        height: '35px',
                      },
                      // Use sx for breakpoints handling
                      '@media (max-width: 1280px)': {
                        width: '27%',
                      },
                      '@media (min-width: 1280px)': {
                        width: '30%',
                      },
                      '@media (min-width: 1920px)': {
                        width: '30%',
                      },
                      '@media (max-width: 1024px)': {
                        width: '27% !important', // Use !important only if needed
                      },
                      '@media (max-width: 768px)': {
                        width: '27% !important',
                      },
                    },
                    '& .input-grid': {
                      '& input': {
                        height: '25px',
                      },
                      // Use sx for breakpoints handling
                      '@media (max-width: 1280px)': {
                        width: '53%',
                      },
                      '@media (min-width: 1280px)': {
                        width: '50%',
                      },
                      '@media (min-width: 1920px)': {
                        width: '50%',
                      },
                      '@media (max-width: 1024px)': {
                        width: '56% !important', // Use !important only if needed
                      },
                      '@media (max-width: 768px)': {
                        width: '56%',
                      },
                    },
                    '& .search-button-grid': {
                      '& > div': {
                        height: '35px',
                      },
                      display: 'flex',
                      alignItems: 'center',
                      width: '5%',
                    },
                  }}>
                    {city_search}
                </Grid>
              </Grid>
            )}
            {!is_open ? (
              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                  md: 5,
                  lg: 6,
                  xl: 6
                }}
                sx={{ flexWrap: 'nowrap' }}>
                <Grid
                  justifyContent="flex-end"
                  container
                  sx={
                    !isJsload && small
                      ? { position: 'absolute', top: '-30px' }
                      : { marginTop: '10px' }
                  }>
                  {is_desktop && (
                    <Grid>
                      <Box sx={styles.sellProperty}>
                        <Box sx={styles.verticalLine}></Box>
                        <Box sx={styles.postDetails}>
                          <img
                            src="/static/images/home-icons.svg"
                            alt="Post Property"
                            style={{
                              objectPosition: '-261px 1px',
                              objectFit: 'cover',
                              width: '26px',
                              height: '24px',
                            }}
                          />
                          {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                          <a
                            href={
                              isUserInfo(user_info)
                                ? '/post-property'
                                : `${MYACCOUNT_HOMEBAZAAR}properties/add`
                            }>
                            Post Property
                          </a>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                  {is_desktop && <Grid>{navbar_buttons}</Grid>}

                  <Grid
                    sx={styles.sidemenuButton}
                    onClick={handleClick}
                    >
                    {sidebar_button}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <div>
                <img src="/static/images/stickysearchicon.svg" alt="search" />
              </div>
            )}
          </Grid>
        </Toolbar>
        {all_filter_buttons && (
          <Grid
            sx={styles.filterButtonsContainer}
            justifyContent="flex-start" // Add it here :)
            container>
            {all_filter_buttons}
          </Grid>
        )}
      </AppBar>
      {showBanner && renderSideMenuBar()}
    </Fragment>)
  );
};

export default Navbar;
