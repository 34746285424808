import { useState, useEffect } from 'react';
import { getItem, storeItem } from '../../../config/LocalStorage';
import { decrypt, encrypt } from '@/components/util';

const useUserInfo = () => {
  const [user_info, setUserInfoState] = useState(null); // Start with null to indicate loading
  const [loginCount, setloginCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      try {
        const userData = decrypt(getItem('user'));
        setUserInfoState(userData);
        setloginCount(getItem('loginCount') ?? 0);
      } catch (error) {
        console.error('Failed to load user info:', error);
        setUserInfoState(null);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const setUserInfo = (user) => {
    storeItem('user', encrypt(user));
    setUserInfoState(user);
  };

  return [user_info, setUserInfo, loginCount, isLoading];
};

export default useUserInfo;
